import React, { Component } from "react";
import {isMobile} from "react-device-detect";

export default function Card(props){
    if (isMobile) {
        return (
            <React.Fragment>
                <div className="m-card m-include-content-margin m-overflow-hidden m-card-container m-card-border">
                    {props.children}
                </div>
            </React.Fragment>
        );
    }
}