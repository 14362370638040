import React, { Component } from "react";

import axios from "axios";
import Cookies from 'js-cookie';
import {isMobile} from 'react-device-detect';

import Card from '../../components/card'
import Content from '../../components/content/content'
import Filters from '../../components/filters/filters'

import './mangas.css';
import {MangaCard} from "./mangas";
import IsLoadingElement from "../../components/isLoadingElement";



const MONTHS = {
    1: "Enero", 2: "Febrero", 3: "Marzo", 4: "Abril", 5: "Mayo", 6: "Junio",
    7: "Julio", 8: "Agosto", 9: "Septiembre", 10: "Octubre", 11: "Noviembre", 12: "Diciembre"
}


export default class Manga extends Component {
    constructor(props) {
        super(props)
        this._refs = props.refs;
        this.navigate = props.navigate
        this.state = {
            volume: null,
            mangas: [],
            page: 0,
            isLoadingScroll: false,
            manga_id: this.getVolumeID()
        }
        this.manga_id = this.state.manga_id
        this.isLoadingScroll =  false
        this.onScrollBind = this.handleScroll.bind(this);
        this.onBackButton = this.onSelectVolume.bind(this)
    }
    componentDidMount() {
        window.addEventListener('scroll', this.onScrollBind);
        window.addEventListener('popstate', this.onBackButton);
        if (!isMobile)
            this.getVolumesMonth(this.state.month, this.state.year)
        else {
            if (this.state.mangas.length == 0 ) {
                this.getVolumes()
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScrollBind)
        window.removeEventListener('popstate', this.onBackButton)
    }
    componentDidUpdate(nextProps) {
        if (nextProps['refs'] !== undefined) {
            Object.keys(nextProps['refs']).map(key => {
                this._refs[key] = nextProps['refs'][key]
            })
        }
    }
    getVolumeID () {
        let manga_id =  window.location.pathname.replace('/', '').replace('manga/', '')
        if (manga_id === "")
            manga_id = null
        return manga_id
    }
    setVolumeID(manga_id) {
        if (this.state.manga_id !== manga_id) {
            this.setState(() => ({
                manga_id: manga_id
            }))
            this.manga_id = manga_id
        }
    }
    onSelectVolume(id) {
        if (this.state.manga_id != id) {
            this.navigate('/manga/' + id);
            this.setState({
                volume: null,
                mangas: [],
                isLoadingScroll: false,
                page: 0
            }, function() {
                this.getVolumes()
            })
        }
    }
    getVolumes() {
        this.getVolumesCallbacks()
            .then(res => {
                if (res) {
                    let page = this.state.page + 1
                    if (res.status === 206) {
                        page = null
                    }
                    this.setState(() => ({
                        volume: res.data['volume'],
                        mangas: this.state.mangas.concat(res.data['collection']),
                        isLoadingScroll:false,
                        page: page
                    }))
                }
            })
        this.isLoadingScroll = false
    }

    async getVolumesCallbacks() {
        if (this.state.page !== null) {
            let url = '/api/manga/id/' + this.state.page + '/';
            let res = await axios({
                method: 'put',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': Cookies.get('csrftoken')
                },
                data: {
                    id: this.getVolumeID()
                }
            })
            return res;
        }
    }
    handleScroll(e) {
        if (!this.isLoadingScroll) {
            this.isLoadingScroll = true
            let top = e.target.documentElement.scrollTop
            let scrollHeihgt = e.target.documentElement.scrollHeight
            let height = window.innerHeight
            if ((scrollHeihgt - top - 40 < height ) && scrollHeihgt !== height ) {
                this.setState(() => ({
                    isLoadingScroll: false,
                }))
                this.getVolumes();
            }
        }
        this.isLoadingScroll = false
    }
    render() {
        let cards = <IsLoadingElement/>
        if (!isMobile) {
            return (<React.Fragment/>);
        } else {
            if (this.state.volume !== null ){
                cards = <React.Fragment>
                    <MangaCard
                        key={0}
                        id={this.state.volume['id']}
                        image={this.state.volume['image']}
                        title={this.state.volume['title']}
                        number={this.state.volume['number']}
                        date={this.state.volume['date']}
                        editorial={this.state.volume['editorial']}
                        credit={this.state.volume['credit']}
                        isOwner={this.state.volume['is_owner']}
                        isReader={this.state.volume['is_reader']}
                        onClick={(id) => null}
                    />
                    {this.state.mangas.map(n => {

                         return (
                             <MangaCard
                                 key={n['id']}
                                 id={n['id']}
                                 image={n['image']}
                                 title={n['title']}
                                 number={n['number']}
                                 date={n['date']}
                                 editorial={n['editorial']}
                                 credit={n['credit']}
                                 isOwner={n['is_owner']}
                                 isReader={n['is_reader']}
                                 onClick={(id) => this.onSelectVolume(id)}
                             />
                        )
                    })}
                    {this.isLoadingScroll && this.state.page!==null ? <IsLoadingElement/> : null}
                </React.Fragment>

            }
        }
        if (!isMobile) {
            return ( <React.Fragment />);
        } else {
            return (
                <React.Fragment>
                    <Content>
                        <Filters
                            navigate={this.navigate}
                            onChange={(filters) => this.onChangeFilters(filters)}
                            rightSearchElement = {'back'}
                            refs={this._refs}/>
                        {cards}
                    </Content>
                </React.Fragment>
            );
        }
  }
}
