import {CircularProgress} from "@mui/material";
import React from "react";

export default function IsLoadingElement(props) {
    return (
        <div className="isLoadingElement">
            <CircularProgress />
        </div>
    );
}
