import { useState } from 'react';
//import { ReactSession } from 'react-client-session';
import Cookies from 'js-cookie';

export default function useToken() {
  const getToken = () => {
    //ReactSession.setStoreType("cookie");
    let tokenString = undefined
    try {
        //tokenString = ReactSession.get('token');
        tokenString = Cookies.get('sessionid')
    } catch (e) {
    }
    if (tokenString == undefined) {
        tokenString = null;
    }
    return tokenString
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    console.log('aaaa')
    console.log(userToken);
    //ReactSession.set('token', JSON.stringify(userToken));
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token
  }
}
