import React from "react";

import {Table as BTable, Button, Row, Col} from 'react-bootstrap'
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table'
import 'bootstrap/dist/css/bootstrap.min.css';
import {isMobile} from "react-device-detect";


function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    _parent
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)
    return (
        <React.Fragment>
            <Row className="justify-content-center">
                Search:{' '}
            </Row>
            <Row>
                <Col  xl={10} md={10} xs={9}>
                    <input
                        className="form-control"
                        value={value || ""}
                        onChange={e => {
                            setValue(e.target.value);
                            onChange(e.target.value);
                        }}
                        placeholder={`${count} records...`}
                    />
                </Col>
                <Col>
                    <Button onClick={(event) => _parent.onClickAddElement(event)}>Add</Button>
                </Col>
            </Row>
        </React.Fragment>
    )
}

function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <input
            className="form-control"
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            placeholder={`Search ${count} records...`}
        />
    )
}

function PaginationTableComponent({ columns, data, _parent}) {
    // Use the state and functions returned from useTable to build your UI
    let hiddenColumns = []
    for (let column of columns) {
        if (column["isVisible"] == false) {
            hiddenColumns.push(column["accessor"])
        }
    }
    const defaultColumn = React.useMemo(
        () => ({
            // Default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        state: { pageIndex, pageSize},
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: {
              pageIndex: 0,
              pageSize: 10,
              hiddenColumns: hiddenColumns
            },

        },
        useFilters,
        useGlobalFilter,
        usePagination
    )
    let styles = {}
    if (isMobile) {
        styles = {"table-layout" : "auto"} ;
        let lastPage = data.length/pageSize
        return (
            <React.Fragment>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    _parent={_parent}
                />
                <BTable styles={styles} borderless striped responsive="sm" {...getTableProps()}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Chapters</th>
                            <th>Season</th>
                            <th>Size</th>
                            <th>Unit</th>
                        </tr>
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </BTable>
                <Row style={{margin:0}}>
                    <Col xs={3} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        <a className="page-link">1</a>
                    </Col>
                    <Col xs={3} onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <a className="page-link">{'<'}</a>
                    </Col>
                    <Col xs={3} onClick={() => nextPage()} disabled={!canNextPage}>
                        <a className="page-link">{'>'}</a>
                    </Col>
                    <Col xs={3} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        <a className="page-link">{lastPage.toFixed(0)}</a>
                    </Col>
                </Row>
                <Row style={{margin:0}}>
                    <Col xs={6}>
                        <a className="page-link">
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>
                        </a>
                    </Col>
                    <Col xs={6}>
                        <select
                            className="form-control"
                            value={pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}
                            style={{ width: '100px', height: '38px' }}
                        >
                            {[5, 10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                  </Col>
                </Row>
            </React.Fragment>
        )
    }
    // Render the UI for your table
    return (
        <React.Fragment>
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                _parent={_parent}
            />
            <BTable styles={styles} borderless striped responsive="sm" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </BTable>
            {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
            <ul className="pagination">
                <li className="page-item" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    <a className="page-link">First</a>
                </li>
                <li className="page-item" onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <a className="page-link">{'<'}</a>
                </li>
                <li className="page-item" onClick={() => nextPage()} disabled={!canNextPage}>
                    <a className="page-link">{'>'}</a>
                </li>
                <li className="page-item" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    <a className="page-link">Last</a>
                </li>
                <li>
                    <a className="page-link">
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </a>
                </li>
                <li>
                    <a className="page-link">
                        <input
                            className="form-control"
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                            style={{ width: '100px', height: '20px' }}
                        />
                    </a>
                </li>{' '}
                <select
                    className="form-control"
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                    style={{ width: '120px', height: '38px' }}
                >
                    {[5, 10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </ul>
        </React.Fragment>
    )
}

export default PaginationTableComponent;