import React, { Component, useState } from "react";
import { NavLink } from "react-router-dom";
import {Button, Row, Col, Modal, Alert} from "react-bootstrap";


import Content from "../../components/basics/content";
import './files.css';
import axios from "axios";
import Cookies from 'js-cookie';
import PaginationTableComponent from "../../components/table"
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


class AlertAnimeExists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show:false
        }
    }
    onVisibility() {
        this.setState(() => ({
            show: true
        }))
    }
    offVisibility(){
        this.setState(() => ({
            show: false
        }))
    }
    render() {
        return (
            <>
                <Alert show={this.state.show} variant="danger">
                    <Alert.Heading>The anime just exist</Alert.Heading>
                    <p>
                        The anime that you select exists in the BBDD
                    </p>
                    <hr/>
                    <div className="d-flex justify-content-end">
                        <Button onClick={() => this.offVisibility()} variant="outline-success">
                            Close
                        </Button>
                    </div>
                </Alert>
            </>
        );
    }
}




class MyAnimeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {show: false, advancedShow: false, alert: false};
        this._parent = props._parent
        this.data = {
            name: React.createRef(),
            downloadName: React.createRef(),
            actualSeason: React.createRef(),
            folder: React.createRef()
        }
        this.advancedRef = React.createRef();
        this.alertRef = React.createRef();
    }
    changeVisibility() {
        this.setState(() => ({
            show: !this.state.show
        }))
    }
    advancedVisibility() {
        this.setState(() => ({
            advancedShow: !this.state.advancedShow
        }))
    }

    onAlertVisiblity() {
        this.setState(() => ({
            alert: true
        }))
    }
    offAlertVisiblity() {
        this.setState(() => ({
            alert: false
        }))
    }
    submit() {
        let data = {
            name: this.data.name.current.value,
            download_name: this.data.downloadName.current.value,
            actual_season: this.data.actualSeason.current.value,
            type_serie: this.data.folder.current.value
        }
        this._parent.addAnime(data, this)
    }

    render() {
        let iconAdvanced =  <ArrowDropDownIcon/>
        let advancedStyle = { display: "none"}
        if (this.state.advancedShow) {
            iconAdvanced = <ArrowDropUpIcon/>
            advancedStyle = {}
        }

        return (
            <Modal
                show={this.state.show} onHide={() => this.changeVisibility()}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add New Anime
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AlertAnimeExists ref={this.alertRef}/>
                    <Row>
                        <Col xs={3} md={3} xl={4}>
                            <label>Name</label>
                        </Col>
                        <Col>
                            <input
                                ref={this.data.name} className="form-control"
                                type="text" placeholder="name"
                                style={{"textAlign": "center"}}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3} md={3} xl={4}>
                            <label>Download Name</label>
                        </Col>
                        <Col>
                            <input
                                ref={this.data.downloadName} className="form-control"
                                type="text" placeholder="download name"
                                style={{"textAlign": "center"}}

                            />
                        </Col>
                    </Row>
                    <Row className="advanced-modal-data">
                        <Col xs={11} md={11} xl={11}>Advanced</Col>
                        <Col onClick={(event) => this.advancedVisibility()}>{iconAdvanced}</Col>
                        <Row className="alert" style={advancedStyle}>
                            <Row>
                                <Col xs={3} md={3} xl={4}>
                                    <label>Actual Season</label>
                                </Col>
                                <Col>
                                    <input
                                        ref={this.data.actualSeason} className="form-control" type="text"
                                        placeholder="Actual Season" defaultValue="1"
                                        style={{"textAlign": "center"}}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={3} md={3} xl={4}>
                                    <label>folder</label>
                                </Col>
                                <Col>
                                    <input
                                        ref={this.data.folder} className="form-control" type="text" placeholder="folder"
                                        defaultValue="anime" style={{"textAlign": "center"}}
                                    />
                                </Col>
                            </Row>
                        </Row>

                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.submit()}>Submit</Button>
                    <Button variant='danger' onClick={() => this.changeVisibility()}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default class Files extends Component {
  constructor(props) {
    super(props)
    this.state = { data: [] };
    this.modalRef = React.createRef();
  }
  componentDidMount() {
      this.getAnimeListCallbacks()
          .then(res => {
              this.setState(() => ({
                  data: res.data
              }))
          })
  }
  componentWillUnmount() {

  }

  onClickAddElement() {
      this.modalRef.current.changeVisibility();
  }

  async getAnimeListCallbacks() {
      let url = '/api/myanime/series/';
      let res = await axios({
          method: 'get',
          url:url,
          headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': Cookies.get('csrftoken')
          },
          data:{}
      })
      return res;
  }
  async addAnimeCallbacks(data) {
      let url = '/api/myanime/series/';
      let res = await axios({
          method: 'post',
          url:url,
          headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': Cookies.get('csrftoken')
          },
          data:data
      })
      return res;
  }

  addAnime(data, modal){
      this.addAnimeCallbacks(data)
          .then(res => {
              let newData = this.state.data.slice()
              newData.push(res.data);
              modal.changeVisibility();
              this.setState(() => ({
                  data:newData
              }))
          })
          .catch(function(error) {
              if (error.response !== undefined) {
                  if (error.response.status === 409)
                      modal.alertRef.current.onVisibility()
                  else
                      throw error
              } else
                  throw error
          })
  }
  render() {


      let columns = [
          {Header: 'Name',accessor: 'name', Cell: e => <NavLink to={"/files/" + e.value}> {e.value} </NavLink>},
          {Header: 'Chapters', accessor: 'num_chapters' },
          {Header: 'Seasons',accessor: 'num_seasons'  },
          {Header: 'Size',accessor: 'size', Cell: e => <div>{e.value.toFixed(2)}</div> },
          {Header: 'Unit',accessor: 'unit'}
      ]
      return (
          <React.Fragment>
              <Content>
                  <PaginationTableComponent columns={columns} data={this.state.data} _parent={this}/>
                  <MyAnimeModal ref={this.modalRef} _parent={this}/>
              </Content>
          </React.Fragment>
      );
  }
}
