import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {Button, Row, Col, Modal, Alert} from "react-bootstrap";
import Content from "../../components/basics/content";
import './files.css';
import axios from "axios";
import Cookies from "js-cookie";

class AlertAnime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show:false,
            title: "",
            message: "",
            color: "danger"
        }
    }
    onVisibility(data) {
        this.setState(() => ({
            show: true,
            title: data.title,
            message: data.message,
            color: data.color
        }))
    }
    offVisibility(){
        this.setState(() => ({
            show: false
        }))
    }

    render() {
        return (
            <>
                <Alert show={this.state.show} variant={this.state.color}>
                    <Alert.Heading>{this.state.title}</Alert.Heading>
                    <p>
                        {this.state.message}
                    </p>
                    <hr/>
                    <div className="d-flex justify-content-end">
                        <Button onClick={() => this.offVisibility()} variant="outline-success">
                            Close
                        </Button>
                    </div>
                </Alert>
            </>
        );
    }
}

export default class SerieView extends Component {
    constructor(props) {
        console.log('constructor')
        super(props)
        let url = window.location.href;
        this.name = url.split('/files/').pop().replaceAll('%20', ' ').replaceAll('/', '')
        this.state = {
            name: '',
            downloadName: '',
            chapters: '',
            seasons: '',
            size: '',
            unit: '',
            actualSeason: '',
            folder: ''
        }
        this.data = {
            downloadName: React.createRef(),
            actualSeason: React.createRef()
        }
        this.alertRef = React.createRef();

    }
    componentDidMount() {
        console.log('componentDidMount')
        this.getAnimeCallbacks()
            .then(res => {
                this.setState({
                    name: res.data['name'],
                    downloadName: res.data['download_name'],
                    chapters: res.data['num_chapters'],
                    seasons: res.data['num_seasons'],
                    size: res.data['size'].toFixed(2),
                    unit: res.data['unit'],
                    actualSeason: res.data['actual_season'],
                    folder: res.data['type_serie']
                })
            })
    }
    componentWillUnmount() {

    }
    async getAnimeCallbacks() {
        let url = '/api/myanime/series/' + this.name + '/';
        let res = await axios({
            method: 'get',
            url:url,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
            data:{}
        })
        return res;
    }
    async updateAnimeCallbacks(data) {
        let url = '/api/myanime/series/' + this.name + '/';
        let res = await axios({
            method: 'put',
            url:url,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
            data:data
        })
        return res;
    }
    onClickSave() {
        let data = {
            download_name: this.data.downloadName.current.value,
            actual_season: this.data.actualSeason.current.value
        }
        let that = this
        this.updateAnimeCallbacks(data)
            .then(res => {
                that.alertRef.current.onVisibility({
                    tittle: "Updated",
                    message: "The serie has been updated with the paramenters",
                    color: "success"
                })
            })
            .catch(function(error) {
                console.log(that)
                if (error.response.status === 406) {
                    that.alertRef.current.onVisibility({
                        tittle: "FAILED",
                        message: "The serie can't be update with the parameters",
                        color: "danger"
                    })
                }
            })
    }
    render() {
        return (
            <React.Fragment>
                <Content>
                    <AlertAnime ref={this.alertRef} />
                    <Row>
                        <Col xs={12} sm={6} xl={4}>
                            <label>Name</label>
                            <fieldset disabled={true}>
                                <input
                                    className="form-control" type="text"
                                    placeholder="Name" defaultValue={this.state.name}
                                    style={{"textAlign": "center"}}
                                />
                            </fieldset>
                        </Col>
                        <Col xs={12} sm={6} xl={4}>
                            <label>Download Name</label>
                            <input
                                className="form-control" type="text"
                                placeholder="Download Name" defaultValue={this.state.downloadName}
                                style={{"textAlign": "center"}} ref={this.data.downloadName}
                            />
                        </Col>
                        <Col xs={12} sm={6} xl={4}>
                            <label>Actual Season</label>
                            <input
                                className="form-control" type="text"
                                placeholder="Actual Season" defaultValue={this.state.actualSeason}
                                style={{"textAlign": "center"}} ref={this.data.actualSeason}
                            />
                        </Col>
                        <Col xs={12} sm={6} xl={4}>
                            <label>Chapters</label>
                            <fieldset disabled={true}>
                                <input
                                    className="form-control" type="text"
                                    placeholder="Chapters" defaultValue={this.state.chapters}
                                    style={{"textAlign": "center"}}
                                />
                            </fieldset>
                        </Col>
                        <Col xs={12} sm={6} xl={4}>
                            <label>Seasons</label>
                            <fieldset disabled={true}>
                                <input
                                    className="form-control" type="text"
                                    placeholder="seasons" defaultValue={this.state.seasons}
                                    style={{"textAlign": "center"}}
                                />
                            </fieldset>
                        </Col>
                        <Col xs={12} sm={6} xl={4}>
                            <label>Folder</label>
                            <fieldset disabled={true}>
                                <input
                                    className="form-control" type="text"
                                    placeholder="Folder" defaultValue={this.state.folder}
                                    style={{"textAlign": "center"}}
                                />
                            </fieldset>
                        </Col>

                        <Col xs={12} sm={6} xl={4}>
                            <label>Size</label>
                            <fieldset disabled={true}>
                                <input
                                    className="form-control" type="text"
                                    placeholder="Size" defaultValue={this.state.size}
                                    style={{"textAlign": "center"}}
                                />
                            </fieldset>
                        </Col>
                        <Col xs={12} sm={6} xl={4}>
                            <label>Unit</label>
                            <fieldset disabled={true}>
                                <input
                                    className="form-control" type="text"
                                    placeholder="Unit" defaultValue={this.state.unit}
                                    style={{"textAlign": "center"}}
                                />
                            </fieldset>
                        </Col>
                    </Row>
                    <Row style={{"marginTop":"15px"}}>
                        <Col xs={10} sm={11} xl={11}></Col>
                        <Col>
                            <Button onClick={(event) => this.onClickSave()}>Save</Button>
                        </Col>
                    </Row>
                </Content>
            </React.Fragment>
      );
  }
}
