import React, { Component } from "react";
import {isMobile} from "react-device-detect";

import './content.css';

export default function Content(props){
    if (isMobile) {
        let minHeight = window.innerHeight - 112;
        return (
            <React.Fragment>
                <div className="m-content m-row" style={{minHeight:minHeight}}>
                    {props.children}
                </div>
            </React.Fragment>
        );
    }
}