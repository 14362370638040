import React, { Component } from "react";
import {isMobile} from "react-device-detect";
import {Form} from 'react-bootstrap'

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import './filters.css';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ElementFilter from './elementFilter'


export default class Filters extends Component {
    constructor(props) {
        super(props);
        this._refs = props.refs;
        this.onScrollBind = this.handleScroll.bind(this);
        this.handlerClickSheetLightBox = this.onClickFilters.bind(this)
        this.divRef = React.createRef()
        this.state = {
            isVisible: true,
            calendarValue: new Date(),
            rightSearchElement: props.rightSearchElement
        }
        this.filters = {}
        this.lastScroll = 0
        this.sheetWebContainer = React.createRef();
        this._refs['prize'] = React.createRef()
        this._refs['input_name'] = React.createRef()
        this.onChange = props.onChange;
        this.rightSearchElement = props.rightSearchElement;
        this.navigate = props.navigate
    }
    componentDidMount() {
        window.addEventListener('scroll', this.onScrollBind);

    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScrollBind);
    }
    static getDerivedStateFromProps(props, current_state) {
        return props
    }
    handleScroll(e) {
        if (this.lastScroll < e.target.documentElement.scrollTop - 80) {
            this.setState(() => ({
                isVisible: false
            }))
            this.lastScroll = e.target.documentElement.scrollTop
        } else if((this.lastScroll > e.target.documentElement.scrollTop + 80) || e.target.documentElement.scrollTop < 100) {
            this.setState(() => ({
                isVisible: true
            }))
            this.lastScroll = e.target.documentElement.scrollTop
        }
    }
    onClickFilters() {
        this._refs.sheetLightBox.current.changeVisibility()
        if (this.sheetWebContainer.current.className.includes(' aok-hidden')) {
            this.sheetWebContainer.current.className = this.sheetWebContainer.current.className.replace(' aok-hidden', '')
            this._refs.sheetLightBox.current.setOnClick(() => this.onClickFilters())
        }else {
            this.sheetWebContainer.current.className = this.sheetWebContainer.current.className.concat(' aok-hidden')
            this._refs.sheetLightBox.current.unsetOnClick()
        }
    }
    onClickBack() {
        this.navigate('/')
        this.onChange({})
    }
    onChangeName(key) {
        if (key === 'Enter') {
            this.filters['name'] = this._refs['input_name'].current.value
            this._refs['input_name'].current.value = ""
            this.onChange(this.filters)
            this.navigate('/')
        }
    }
    handleDateChange(value) {
        this.setState(() => ({
            calendarValue: new Date(value)
        }));
        this.filters['date'] = new Date(value)
        this.onChange(this.filters)
    }
    handlePrizeChange(value) {
        this.filters['prize'] = this._refs['prize'].current.value
        this.onChange(this.filters)
    }
    handleEditorialChange(value) {
        this.filters['editorial'] = value;
        this.onChange(this.filters);
    }
    handleOwnerChange(value) {
        this.filters['owner'] = value;
        this.onChange(this.filters);
    }
    render() {
        let styles = {
            mSheetWebContainer : {transform:"translateY(0px)", height:window.innerHeight * 65/100 + "px"},
            mWebContainer: {height:window.innerHeight * 60/100+ "px", maxHeight: window.innerHeight * 60/100 + "px"}
        }
        let rightSearchElement = null
        if (this.state.rightSearchElement === 'filters') {
            rightSearchElement = <div className="m-filters-expand float-right" onClick={()=> this.onClickFilters()}>
                <div className="i-flex a-size-small a-color-link">
                    <span>Filtros</span>
                    <ArrowDropDownIcon />
                </div>
            </div>
        } else if (this.state.rightSearchElement === 'back') {
            rightSearchElement = <div className="m-filters-expand float-right" onClick={()=> this.onClickBack()}>
                <div className="i-flex a-size-small a-color-link">
                    <span>Back</span>
                    <ArrowBackIcon />
                </div>
            </div>
        }
        if (isMobile) {
            let classValue = this.state.isVisible ?
                "m-toolbar-sticky m-flex-full-width  full-width" : "m-toolbar-sticky m-flex-full-width s-mobile-toolbar-offscreen full-width"
            return (
                <React.Fragment>
                    <div ref={this.divRef} className={classValue}>
                        <div className="m-toolbar">
                            <div className="m-filter-scroller a-scroller">
                                <input
                                    ref={this._refs['input_name']}
                                    className="m-filter-search-name"
                                    placeholder="Manga"
                                    onKeyDown={(event) => this.onChangeName(event.key)}/>
                                <div className="m-search-icon" onClick={() => this.onChangeName('Enter')}>
                                    <SearchIcon/>
                                </div>

                            </div>
                            {rightSearchElement}
                        </div>
                    </div>
                    <div ref={this.sheetWebContainer} className="m-sheet-web-container a-experimental-ios-scrolling aok-hidden" style={styles.mSheetWebContainer}>
                        <div className="m-sheet-web sf-rounded-corners" style={styles.mWebContainer}>
                            <div className="m-filters-headers">
                                <div className="m-filters-headers-span a-scroller">
                                    <span>Filters</span>
                                </div>
                                <div className="m-filters-headers-close float-right" onClick={()=> this.onClickFilters()}>
                                    <span className="a-color-link">Cerrar</span>
                                </div>
                            </div>
                            <div className="m-filters-first-item">
                                <div className="m-filters-item">
                                    <div className="m-filters-calendar">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="dd MMM-yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                label="Fecha de salida"
                                                value={this.state.calendarValue}
                                                onChange={(value) => this.handleDateChange(value)}
                                                KeyboardButtonProps={{
                                                  'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <hr aria-hidden="true" className="a-spacing-none a-divider-normal" />
                                </div>
                                <div className="m-filters-item">
                                    <div>
                                        <a>Precio</a>
                                        <div className="m-filters-item-values">
                                            <Form.Select ref={this._refs['prize']} aria-label="Default select example"
                                                onChange={(value) => this.handlePrizeChange(value)}
                                            >
                                                <option value="0" selected>Todos los precios</option>
                                                <option value="1">0€ - 10€</option>
                                                <option value="2">10€ - 20€</option>
                                                <option value="3">20€ - 30€</option>
                                                <option value="4">30€ - 50€</option>
                                                <option value="5">50€</option>
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <hr aria-hidden="true" className="a-spacing-none a-divider-normal" />
                                </div>
                                <ElementFilter
                                    name={"Editoriales"}
                                    allElement={"Todas"}
                                    elements={["Norma", "Ivrea", "Planeta Cómic", "ECC Ediciones", "Milky Way", "Panini"]}
                                    ref={this._refs['editorial']}
                                    onChange={(value) => this.handleEditorialChange(value)}
                                />
                                <ElementFilter
                                    name={"Selección"}
                                    allElement={"Todas"}
                                    elements={["Mis colecciones", "Mis lecturas"]}
                                    onlyOne={true}
                                    ref={this._refs['owners']}
                                    onChange={(value) => this.handleOwnerChange(value)}
                                />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }
}