
import React, { Component } from 'react'
import HomeIcon from '@mui/icons-material/Home';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import { Navbar, Nav, Container, Offcanvas }from 'react-bootstrap'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

class Footer extends Component{
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <Navbar bg="dark" variant="dark" style={{"flex": "0 1 auto",  height: "56px"}}>
                    <Container fluid>
                        <Navbar.Brand>© 2022 Copyright:</Navbar.Brand>
                    </Container>
                </Navbar>
            </React.Fragment>
        )
    }
}

export default Footer;