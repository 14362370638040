import React, { Component, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Container }from 'react-bootstrap'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';


export default class Background extends Component{
  constructor(props) {
    super(props)
  }
  componentDidMount() {

  }
  componentWillUnmount() {

  }

  render() {
    return (
        <Container fluid className="h-100 outer" style={{"margin": 0, "padding":0}}>
          <div className="row justify-content-center h-100" style={{"margin":"0%", "flexFlow": "column"}}>
            {this.props.children}
          </div>
        </Container>
    )
  }
}