import React, { Component } from "react";
import {isMobile} from "react-device-detect";

export default class SheetLighbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false
        }
        this.doFunction = null;
    }
    changeVisibility() {
        this.setState(() => ({
            isVisible: !this.state.isVisible
        }));
    }
    setOnClick(doFunction) {
        this.doFunction = doFunction;
    }
    unsetOnClick(){
        this.doFunction = null
    }
    onClick() {
        if (this.doFunction !== null) {
            this.doFunction()
        }
    }
    render() {
        let lightBox = null
        if (this.state.isVisible) {
            if (isMobile) {
                lightBox = <div className="m-sheet-lightbox sf-lightbox" onClick={()=>this.doFunction()}/>
            }
        }
         return (
             <React.Fragment>
                 {lightBox}
             </React.Fragment>
         );
    }
}