import React, { Component } from "react";
import {Row, Col} from "react-bootstrap";

import axios from "axios";
import Cookies from 'js-cookie';
import {isMobile} from 'react-device-detect';

import Card from '../../components/card'
import Content from '../../components/content/content'
import Filters from '../../components/filters/filters'

import './mangas.css';

import IsLoadingElement from "../../components/isLoadingElement";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';


const MONTHS = {
    1: "Enero", 2: "Febrero", 3: "Marzo", 4: "Abril", 5: "Mayo", 6: "Junio",
    7: "Julio", 8: "Agosto", 9: "Septiembre", 10: "Octubre", 11: "Noviembre", 12: "Diciembre"
}


class Mangas extends Component {
    constructor(props) {
        super(props)
        this._refs = props.refs;
        this.navigate = props.navigate
        let current = new Date();
        this.month_name = MONTHS[this.month]
        this.state = {
            mangas: [],
            month: current.getMonth() + 1,
            year: current.getFullYear(),
            page: 0,
            isLoadingScroll: false,
            manga_id: this.getMangaID()
        }
        this.manga_id = this.state.manga_id
        this.isLoadingScroll =  false
        this.onScrollBind = this.handleScroll.bind(this);
        this.onBackButton = this.handleBackButton.bind(this)
        this._refs['filters'] = React.createRef()
        this.filters = {}
    }
    componentDidMount() {
        window.addEventListener('scroll', this.onScrollBind, { passive: true });
        window.addEventListener('popstate', this.onBackButton);
        if (!isMobile)
            this.getVolumesMonth(this.state.month, this.state.year)
        else {
            if (this.state.mangas.length == 0 ) {
                this.getVolumes()
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScrollBind)
        window.removeEventListener('popstate', this.onBackButton)
    }
    componentDidUpdate(nextProps) {
        if (nextProps['refs'] !== undefined) {
            Object.keys(nextProps['refs']).map(key => {
                this._refs[key] = nextProps['refs'][key]
            })
        }
    }
    getMangaID () {
        let manga_id =  window.location.pathname.replace('/', '').replace('manga/', '')
        if (manga_id === "")
            manga_id = null
        return manga_id
    }
    setMangaID(manga_id) {
        if (this.state.manga_id !== manga_id) {
            this.setState(() => ({
                manga_id: manga_id
            }))
            this.manga_id = manga_id
        }
    }
    getVolumesMonth(month, year) {
        this.getVolumesMonthCallbacks(month, year)
            .then(res => {
                this.setState(() => ({
                    mangas: res.data,
                    month:month,
                    year:year
                }))
            })
    }
    onSelectManga(id) {

        if (this.state.manga_id != id) {
            this.navigate('/manga/' + id);
            this.onChangeFilters({})
        }
    }
    onChangeFilters(filters) {

        let manga_id = this.getMangaID()
        this.setMangaID(manga_id)
        this.filters = filters
        this.state.page = 0
        this.state.mangas = []
        if (manga_id === null) {
            this.getVolumes()
        }
    }
    getVolumes() {
        let manga_id = this.getMangaID()
        if (manga_id !== null)
            this.filters['id'] = manga_id
        this.getVolumesCallbacks()
            .then(res => {
                if (res) {
                    let page = this.state.page + 1
                    if (res.status === 206) {
                        page = null
                    }
                    this.setState(() => ({
                        mangas: this.state.mangas.concat(res.data),
                        page: page
                    }))
                }
            })
    }

    async getVolumesMonthCallbacks(month, year) {
        if (!isMobile) {
            let url = '/api/manga/month/' + year + month + '/';
            let res = await axios({
                method: 'get',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': Cookies.get('csrftoken')
                },
                data: {}
            })
            return res;
        }
    }

    async getVolumesCallbacks() {
        if (this.state.page !== null) {
            let url = '/api/manga/list/' + this.state.page + '/';
            let res = await axios({
                method: 'put',
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': Cookies.get('csrftoken')
                },
                data: {
                    filters: this.filters
                }
            })
            return res;
        }
    }
    handleBackButton() {
        this.onChangeFilters({})
    }
    handleScroll(e) {
        if (!this.isLoadingScroll) {
            this.isLoadingScroll = true
            let top = e.target.documentElement.scrollTop
            let scrollHeight = e.target.documentElement.scrollHeight
            let height = window.innerHeight
            if ((scrollHeight - top - 10 <= height ) && scrollHeight !== height ) {
                this.getVolumes();
            }
            this.isLoadingScroll = false
        }
    }
    render() {
        let cards = <IsLoadingElement/>
        if (!isMobile) {
            if (Object.keys(this.state.mangas).length > 0){
                cards = <React.Fragment>
                    <div className="date-release">
                        <Row>
                            <Col
                                className="left date-field"
                                sm={4} xs={4} xl={4} md={4}
                                onClick={(event) => this.getVolumesMonth(
                                    this.state.month - 1,
                                    this.state.year
                                )}
                            >
                                <h4><b>{MONTHS[this.state.month - 1]} {this.state.year}</b></h4>
                            </Col>
                            <Col className="center date-field" sm={4} xs={4} xl={4} md={4}>
                                <h4><b>{MONTHS[this.state.month]} {this.state.year}</b></h4>
                            </Col>
                            <Col
                                className="right date-field"
                                sm={4} xs={4} xl={4} md={4}
                                sm={4} xs={4} xl={4} md={4}
                                onClick={(event) => this.getVolumesMonth(
                                    this.state.month + 1,
                                    this.state.year
                                )}
                            >
                                <h4><b>{MONTHS[this.state.month + 1]} {this.state.year}</b></h4>
                            </Col>
                        </Row>
                    </div>
                    {Object.keys(this.state.mangas).map(date => {
                            let select_style = "mangas";
                            if (!isMobile && this.state.mangas[date].length < 8)
                                select_style = "mangas-few";
                            return (
                                <div className="date-release">
                                    <div className="center">
                                        <div className="date-title">
                                            <h5>{date}</h5>
                                        </div>
                                    </div>


                                    <div className={select_style}>
                                        {this.state.mangas[date].map(n => {
                                                return (
                                                    <div className="manga">
                                                        <div className="manga-image">
                                                            <img className="volume-poster" src={n['image']}/>
                                                            <div className="volume-metadata">
                                                                {n['title']} {n['number']}
                                                            </div>
                                                        </div>
                                                        <div className="prize">
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )}
                                    </div>
                                </div>
                            )
                        }
                    )}
                </React.Fragment>
            }
        } else {
            if (this.state.mangas.length > 0){

                cards = <React.Fragment>
                    {this.state.mangas.map(n => {
                         return (
                             <MangaCard
                                 key={n['id']}
                                 id={n['id']}
                                 image={n['image']}
                                 title={n['title']}
                                 number={n['number']}
                                 date={n['date']}
                                 editorial={n['editorial']}
                                 credit={n['credit']}
                                 isOwner={n['is_owner']}
                                 isReader={n['is_reader']}
                                 onClick={(id) => this.onSelectManga(id)}
                             />
                        )
                    })}
                    {this.isLoadingScroll && this.state.page!==null ? <IsLoadingElement/> : null}
                </React.Fragment>

            }
        }
        if (!isMobile) {
            return (
                <React.Fragment>
                    <ContentPrivate title="HOME">
                        {cards}
                    </ContentPrivate>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Content>
                        <Filters
                            navigate={this.navigate}
                            onChange={(filters) => this.onChangeFilters(filters)}
                            rightSearchElement = {this.manga_id == null ? 'filters' : 'back'}
                            ref={this._refs['filters']}
                            refs={this._refs}/>
                        {cards}
                    </Content>
                </React.Fragment>
            );
        }
  }
}



class ContentPrivate extends Component{
    constructor(props) {
        super(props)
    }
    componentDidMount() {

    }
    componentWillUnmount() {

    }

    render() {
        if (!isMobile){
            return (
                <React.Fragment>
                    <Row style={{"flex": "1 1 auto"}}>
                        <Col></Col>
                        <Col className="home-content" sm={6} md={10} xl={10}>
                            {this.props.children}
                        </Col>
                        <Col></Col>
                    </Row>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Row style={{margin:0, "flex": "1 1 auto", }}>
                        <Row style={{marginTop:"15px"}}>
                            {this.props.children}
                        </Row>
                    </Row>
                </React.Fragment>
            );
        }
    }
}

class MangaOwnerClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOwner: props.isOwner,
            isReader: props.isReader,
        }
        this.id = props.id;
    }
    componentDidMount() {

    }
    componentWillUnmount() {

    }
    onClickAddVolumeToCollection() {
        if (!this.state.isOwner) {
            this.addVolumeToCollectionCallback()
                .then(res => {
                if (res) {
                    if (res.status === 201) {
                        this.setState(() => ({
                            isOwner: true
                        }))
                    }
                }})
        }
    }
    async addVolumeToCollectionCallback() {
        let url = '/api/manga/add/' + this.id + '/';
        let res = await axios({
            method: 'put',
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': Cookies.get('csrftoken')
            },
            data: {}
        })
        return res;
    }
    render() {
        return (
            <div className="div-manga-owner">
                <span style={{"float":"left"}} onClick={() => this.onClickAddVolumeToCollection()}>
                    {this.state.isOwner && <CheckCircleIcon style={{"color":"green"}}/>}
                    {!this.state.isOwner && <AddCircleIcon style={{"color":"midnightblue"}}/>}
                </span>
                <span style={{"float":"right"}}>
                    {this.state.isReader && <BookmarkAddedIcon style={{"color":"green"}}/>}
                    {!this.state.isReader && <BookmarkAddIcon style={{"color":"midnightblue"}}/>}
                </span>
            </div>
        )
    }
}

function MangaCard(props) {
    return (
        <Card key={props.id}>
            <div className="m-row">
                <div className="m-col" onClick={() => props.onClick(props.id)}>
                    <div className="col-image">
                        <div className="c-image">
                            <img className="poster" src={props.image} loading="lazy"/>
                        </div>
                    </div>
                </div>
                <div className="m-col-fill spacing-top-small spacing-bottom-small">
                    <div className="div-manga-info" onClick={() => props.onClick(props.id)}>
                        <span className="mobile-span-3-text mobile-span-max-height-60">
                            <h1><b>{props.title}</b> {props.number}</h1>
                        </span>
                        <span className="mobile-span-2-text mobile-span-max-height-30">
                            <h3>Fecha: {props.date}</h3>
                        </span>
                        <span className="mobile-span-2-text mobile-span-max-height-30">
                            <h3 >{props.editorial}</h3>
                        </span>
                        <span className="mobile-span-2-text mobile-span-max-height-30">
                            <h2>Precio: {props.credit} €</h2>
                        </span>
                    </div>
                    <MangaOwnerClass
                        key={props.id}
                        id={props.id}
                        isOwner={props.isOwner}
                        isReader={props.isReader}
                    />
                </div>
            </div>
        </Card>
    )
}

export {Mangas, MangaCard, MangaOwnerClass};