import React, { Component } from 'react'
import {Row, Col} from "react-bootstrap";
import PropTypes from 'prop-types'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {isMobile} from 'react-device-detect';




export default class Content extends Component{
  constructor(props) {
    super(props)
  }
  componentDidMount() {

  }
  componentWillUnmount() {

  }

  render() {
      if (!isMobile){
          return (
              <React.Fragment>
                  <Row style={{"flex": "1 1 auto"}}>
                      <Col></Col>
                      <Col className="inner" sm={6} md={10} xl={10}>
                          {this.props.children}
                      </Col>
                      <Col></Col>
                </Row>
            </React.Fragment>
          );
      } else {
          return (
              <React.Fragment>
                  <Row style={{margin:0,"background":"#ffffff", "flex": "1 1 auto", }}>
                      <Row style={{marginTop:"15px"}}>
                          {this.props.children}
                      </Row>

                  </Row>
              </React.Fragment>
          );
      }
  }
}