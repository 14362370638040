import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import axios from "axios";
//import Cookies from 'js-cookie'

export default class NavTop extends Component {    
    constructor() {
        super();
        this.data = []
        this.items = []
        this.state = {
            error: null,
            isLoaded: false,
            items: []
        };
      }
    componentDidMount() {
        axios({
            method: 'get',
            url:'/api/authentication/get_elements_nav/',
            headers: {'Content-Type': 'application/json'}
        })
        .then(res => {
            this.setState({
                isLoaded: true,
                items: res['data']
            });

        })
 
    }

    render() { 
        console.log(1)
        const { error, isLoaded, items } = this.state;
        if (error) {
          return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
          return <div>Loading...</div>;
        } else {
            let components = [];
            console.log()
            for(var i = 0;i<items.length;i++) {
                let href_item = "/" + items[i]
                components.push(
                    <li  class="nav-item active" key={items[i]}>
    
                        <a class="nav-link" href={href_item}>
                            {items[i]} 
                        </a>
                    </li>
                );
            }
            console.log(components)
            return (
                <nav class="navbar navbar-expand-lg navbar navbar-dark bg-dark">
                    <div className="container">
                        <a class="navbar-brand" href="#">LordMarquez</a>
                        <div class="collapse navbar-collapse" id="navbarNavDropdown">
                            <ul className="navbar-nav ml-auto">
                                {components}
                            </ul>
                        </div>
                    </div>
                </nav>
            )
        }
    }
}