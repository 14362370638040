import React, { Component } from "react";
import {isMobile} from "react-device-detect";
import {Form} from 'react-bootstrap'

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import './filters.css';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


export default class ElementFilter extends Component {
    constructor(props) {
        super(props);
        this.name = props.name
        this.allElement = props.allElement;
        this.elements = props.elements;
        this.state = {
            elementsSelected: [this.allElement]
        }
        this.onChange = props.onChange
        this.ref = { }
        this.ref[this.allElement] = React.createRef()
        this.elements.map((value) => {
            this.ref[value] = React.createRef()
       })
        if (this.props.onlyOne !== undefined) {
            this.onlyOne = props.onlyOne;
        }

    }
    componentDidMount() {
    }
    componentWillUnmount() {
    }
    onClick(value) {
        let array = []
        if (value == this.allElement || this.onlyOne) {
            array =  [value]
            this.setState(() => ({
                elementsSelected: array
            }))
        }else {
            if (this.state.elementsSelected.includes(this.allElement)) {
                array = [value]
                this.setState(() => ({
                    elementsSelected:array
                }))
            } else if (this.state.elementsSelected.includes(value)) {
                array = this.state.elementsSelected
                array.splice(array.indexOf(value))
                if (array.length == 0 ) {
                    array = [this.allElement]
                }
                this.setState(() => ({
                    elementsSelected: array
                }))
            } else {
                array = this.state.elementsSelected
                array.push(value)
                this.setState(() => ({
                    elementsSelected: array
                }))
            }
        }
        this.onChange(array)
    }
    render() {
        let filter = null
        if (isMobile) {
            filter =  <React.Fragment>
                <div className="m-filters-item">
                    <div>
                        <a>{this.name}</a>
                        <div className="m-filters-item-values">
                            <span className="m-filters-item-values-flex">
                                <span ref={this.ref[this.allElement]} className={this.state.elementsSelected.includes(this.allElement) ?
                                    "m-filters-item-value m-filter-item-selected": "m-filters-item-value"}
                                      onClick={() => this.onClick(this.allElement)}
                                >
                                    <div>
                                        <span>Todas</span>
                                    </div>
                                </span>
                                {this.elements.map(n => {
                                    return (
                                         <span key={n} ref={this.ref[n]} className={this.state.elementsSelected.includes(n) ?
                                            "m-filters-item-value m-filter-item-selected": "m-filters-item-value"}
                                            onClick={() => this.onClick(n)}
                                         >
                                            <div>
                                                <span>{n}</span>
                                            </div>
                                        </span>
                                    )
                                })}
                            </span>
                        </div>
                    </div>
                    <hr aria-hidden="true" className="a-spacing-none a-divider-normal" />
                </div>
            </React.Fragment>
        }
         return (
             <React.Fragment>
                 {filter}
             </React.Fragment>
         );
    }
}