import React, { Component } from "react";
import axios from "axios";
import Cookies from 'js-cookie';
import CSRFToken from './csrftoken';
import Content from './basics/content';
import PropTypes from 'prop-types';
import {Button, Row, Col} from "react-bootstrap";

class Login extends Component {
    constructor(setToken) {
        super();
        this.data = { 
        }
        this.onInputchange = this.onInputchange.bind(this);
        this.onLogForm = this.onLogForm.bind(this);
        this.setToken = setToken;
    }
    async onLogForm() {
        axios.defaults.headers.post['X-CSRFToken'] = Cookies.get('csrftoken');
        let url = '/api/login/';
        await axios({
            method: 'post',
            url:url,
            headers: {
                'Content-Type': 'application/json',
            },
            data:this.data
        })
        .then(res => {
            const data = res.data;
            console.log(Cookies.get('sessionid'))
            this.setToken.setToken(Cookies.get('sessionid'))
        })
    }
    onInputchange(event) {
        this.data[event.target.name] = event.target.value
    }
    render() {
        return (
            <React.Fragment>
                <Content title="Login">
                    <Row style={{display:"inline-block"}}>
                        <Row>
                            <label>username</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter username"
                                name="username"
                                value={this.data.username}
                                onChange={this.onInputchange}
                            />
                        </Row>

                        <Row style={{display:"inline-block"}}>
                            <label>Password</label>
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Enter password"
                                name="password"
                                value={this.data.password}
                                onChange={this.onInputchange}
                            />
                        </Row>
                        <Button onClick={this.onLogForm} className="btn btn-dark">Sign in</Button>
                    </Row>



                </Content>
            </React.Fragment>
        );
    }
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};

export default Login;