import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import MetaTags from 'react-meta-tags';
import {isMobile} from 'react-device-detect';
import Cookies from 'js-cookie';

import Header from "./components/basics/Navar/header";
import Footer from "./components/basics/footer";
import Background from "./components/basics/background";
import SheetLighbox from "./components/sheet-lighbox";


import Login from "./components/login";
import NavTop from "./components/nav-top";

import {Mangas} from "./views/mangas/mangas";
import Manga from "./views/mangas/manga";
import Files from ".//views/files/files";
import Serie from "./views/files/file"
import useToken from "./components/useToken";

function App() {
    const { token, setToken } = useToken();
    const navigate = useNavigate();
    const refs = {
        sheetLightBox: React.createRef()
    }

    if(!token) {
        return (
            <React.Fragment>
                <Header />
                <Background>
                    <Login setToken={setToken} />
                </ Background>
                <Footer />
            </React.Fragment>
        );
    }
    if (isMobile){
        return (
            <React.Fragment>
                <MetaTags>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                </MetaTags>

                <div className="mobile-container">
                    <Header navigate={navigate}/>
                    <Routes>
                        <Route exact path='/' element={<Mangas refs={refs} navigate={navigate}/>} />
                        <Route path='/manga/:id' element={<Manga refs={refs} navigate={navigate}/>} />
                        <Route path='/files' element={<Files />} />
                        <Route path='/files/:name' element={<Serie />} />
                    </Routes>
                    <Footer/>
                    <SheetLighbox ref={refs.sheetLightBox}/>
                </div>
            </React.Fragment>
        )
    }
    else {
        return (
            <React.Fragment>
                <MetaTags>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                </MetaTags>
                <div className="App">
                    <Background>
                        <Header navigate={navigate}/>
                        <Routes>
                            <Route exact path='/' element={<Mangas refs={refs} />}/>
                            <Route path='/files' element={<Files/>}/>
                            <Route path='/files/:name' element={<Serie/>}/>
                        </Routes>
                        <Footer/>
                    </Background>
                </div>
            </React.Fragment>
        );
    }

}

export default App;

